<template>
  <v-card
    outlined
    class="d-flex flex-column"
  >
    <v-card-title>
      <h5>{{ $t('labels.filter-contacts') }}</h5>
      <v-spacer />
      <v-btn
        v-if="canClose"
        icon
        @click="close"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="py-2 dialog-content-overflow">
      <v-expansion-panels
        v-model="panel"
        accordion
        flat
      >
        <v-expansion-panel class="my-1 expansion-panel-border">
          <v-expansion-panel-header class="px-2">
            <span class="text-body-1">
              {{ $t('lead-list.filter-settings.labels.in-edit') }}
              <v-badge
                color="red"
                :value="isEditFilterActive"
                :content="editFilterActiveCount"
                class="ml-1"
              />
            </span>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="filter-panel">
            <v-select
              v-model="filters.currentStatus"
              :items="statusList"
              :label="$t('lead-list.filter-settings.labels.status')"
              class="mb-4"
              hide-details
              clearable
              outlined
              dense
            />
            <Feature :feature-slug="featureNames.ASSIGN_LEADS">
              <Permission permission="lead:assign">
                <v-select
                  v-model="filters.assignedUser"
                  :items="assignedUserList"
                  :label="$t('lead-list.filter-settings.labels.assigned-employee')"
                  hide-details
                  clearable
                  outlined
                  dense
                />
              </Permission>
            </Feature>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel
          v-if="hasCrm"
          class="my-1 expansion-panel-border"
        >
          <v-expansion-panel-header class="px-2">
            <span class="text-body-1">
              {{ crmContactStatusLabel }}
              <v-badge
                color="red"
                :value="isTagFilterActive"
                :content="tagFilterActiveCount"
                class="ml-1"
              />
            </span>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="filter-panel">
            <TagFilter
              :initial-selected-tags="contactTags"
              :crm-name="crmName"
              @change="updateContactTypes"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel class="my-1 expansion-panel-border">
          <v-expansion-panel-header class="px-2">
            <span class="text-body-1">
              {{ $t('lead-list.filter-settings.labels.contact-actions') }}
              <v-badge
                color="red"
                :value="isConversionFilterActive"
                :content="conversionFilterActiveCount"
                class="ml-1"
              />
            </span>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="filter-panel">
            <span class="text-body-2">{{ $t('lead-list.filter-settings.labels.contact-display') }}</span>
            <div>
              <v-checkbox
                v-for="({key, label, icon},index) of conversionFilters"
                :key="key"
                v-model="filters[key]"
                hide-details
                :class="{'mt-0':index===0}"
              >
                <template v-slot:label>
                  <div>
                    <v-icon small>
                      {{ icon }}
                    </v-icon>
                    {{ $t(label) }}
                  </div>
                </template>
              </v-checkbox>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <Feature :feature-slug="featureNames.POSTAL_SHIPPING">
          <v-expansion-panel class="my-1 expansion-panel-border">
            <v-expansion-panel-header class="px-2">
              <span class="text-body-1">
                {{ $t('lead-list.filter-settings.labels.send-option') }}
                <v-badge
                  color="red"
                  :value="filters.postalShipping"
                  content="1"
                  class="ml-1"
                />
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="filter-panel">
              <span class="text-body-2">{{ $t('lead-list.filter-settings.labels.contacts-with') }}</span>
              <v-checkbox
                v-model="filters.postalShipping"
                class="mt-0"
                hide-details
                label="postalischem Versand"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </Feature>

        <v-expansion-panel class="my-1 expansion-panel-border">
          <v-expansion-panel-header class="px-2">
            <span class="text-body-1">
              {{ $t('lead-list.filter-settings.labels.contact-type') }}
              <v-badge
                color="red"
                :value="isContactTypeFilterActive"
                content="1"
                class="ml-1"
              />
            </span>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="py-0 filter-panel">
            <v-select
              v-model="filterContactType"
              :items="originList"
              :label="$t('lead-list.filter-settings.labels.contact-type')"
              class="mb-4"
              hide-details
              outlined
              dense
            />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <Feature :feature-slug="featureNames.ACQUISITION_BOOSTER">
          <v-expansion-panel
            class="my-1 expansion-panel-border"
          >
            <v-expansion-panel-header class="px-2">
              <span class="text-body-1">
                {{ $t('lead-list.filter-settings.labels.acquisition-booster-status') }}
                <v-badge
                  color="red"
                  :value="isInvitationFilterActive"
                  content="1"
                  class="ml-1"
                />
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="py-0 filter-panel">
              <v-select
                v-model="filters.invited"
                :items="invitedLandingpageSlugs"
                :label="$t('lead-list.filter-settings.labels.acquisition-booster-topic')"
                class="mb-4"
                hide-details
                clearable
                outlined
                dense
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </Feature>
        <v-expansion-panel
          class="my-1 expansion-panel-border"
        >
          <v-expansion-panel-header class="px-2">
            <span class="text-body-1">
              {{ $t('lead-list.filter-settings.labels.contact-sources') }}
              <v-badge
                color="red"
                :value="isSourceFilterActive"
                content="1"
                class="ml-1"
              />
            </span>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="py-0 filter-panel">
            <v-select
              v-model="filters.source"
              :items="trafficSourceList"
              :label="$t('lead-list.filter-settings.labels.contact-source')"
              class="mb-4"
              hide-details
              clearable
              outlined
              dense
            />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel
          class="my-1 expansion-panel-border"
        >
          <v-expansion-panel-header class="px-2">
            <span class="text-body-1">
              {{ $t('lead-list.filter-settings.labels.downloads') }}
              <v-badge
                color="red"
                :value="isDownloadLandingpageFilterActive"
                content="1"
                class="ml-1"
              />
            </span>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="py-0 filter-panel">
            <v-select
              v-model="filters.downloadLandingpage"
              :items="downloadLandingpages"
              :label="$t('lead-list.filter-settings.labels.acquisition-booster-topic')"
              class="mb-4"
              hide-details
              clearable
              outlined
              dense
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <p class="mt-8 text-caption">
        {{ $t('lead-list.filter-settings.info') }}
      </p>
    </v-card-text>

    <v-spacer />

    <v-card-actions class="justify-end d-flex">
      <v-btn
        right
        :disabled="activeFiltersCount<=0"
        small
        color="error"
        class="w-full"
        @click="resetFilters()"
      >
        {{ $t('buttons.lead-list.filter-settings.cta') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import USERS from '../queries/Users.gql'
import COMPANY from '../queries/Company.gql'
import LANDINGPAGE_SLUGS_USED_FOR_INVITATION from '../queries/LandingpageSlugsUsedForInvitation.gql'
import TOTAL_TRAFFIC_BY_SOURCE_FOR_COMPANY from '../queries/TotalTrafficBySourceForCompany.gql'
import { statusForSelect as statusList } from '@/lib/leadStatus'
import { originForSelect as originList } from './lib/leadOrigin'
import { TOOLS_WITH_DOWNLOADS } from '@/lib/tools'
import { getActiveFilterCount, persistAllFilters, restoreAllFilters } from '../helper/filter'
import TagFilter from './TagFilter.vue'
import featureMixin from '@/mixins/feature'
import branding from '@/mixins/branding'
import Permission from '@/components/Permission'
import { Feature } from 'feature-config'
import { sortByString } from '@/lib/sort-functions'

export default {
  components: {
    TagFilter,
    Permission,
    Feature
  },
  mixins: [featureMixin, branding],
  props: {
    hasTool: {
      type: Function,
      default: () => {}
    },
    invitedLandingpageSlugs: {
      type: Array,
      default: () => []
    },
    canClose: {
      type: Boolean,
      default: true
    },
    hasCrm: {
      type: Boolean,
      default: false
    },
    crmName: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      filters: {
        currentStatus: null,
        assignedUser: null,
        invited: null,
        source: null,
        downloadLandingpage: null,
        leadOriginConversion: true,
        leadOriginImport: true,
        postalShipping: false,
        leadOriginCrm: false,
        ...TOOLS_WITH_DOWNLOADS.reduce((obj, { key }) => ({ ...obj, [key]: false }), {}),
        tags: []
      },
      totalTrafficBySourceForCompany: [],
      filterContactType: 'leadOriginAll',
      activeFiltersCount: 0,
      statusList: statusList.map(item => { // create translated status list
        return {
          ...item,
          text: this.$t(item.text)
        }
      }),
      panel: -1
    }
  },

  computed: {
    conversionFilters () {
      return TOOLS_WITH_DOWNLOADS.filter(({ type }) => this.hasTool(type))
    },
    contactTags () {
      return this.filters?.tags || []
    },
    editFilterActiveCount () {
      return [
        this.filters.currentStatus,
        this.filters.assignedUser
      ].filter(Boolean).length
    },
    tagFilterActiveCount () {
      return this.filters?.tags?.length
    },
    conversionFilterActiveCount () {
      return [...this.conversionFilters.map(({ key }) => this.filters[key])].filter(Boolean).length
    },
    contactTypeFilterActiveCount () {
      return [
        this.filters.leadOriginConversion,
        this.filters.leadOriginImport,
        this.filters.leadOriginCrm
      ].filter(Boolean).length
    },
    isConversionFilterActive () {
      return this.conversionFilterActiveCount > 0
    },
    isEditFilterActive () {
      return this.editFilterActiveCount > 0
    },
    isTagFilterActive () {
      return this.tagFilterActiveCount > 0
    },
    isContactTypeFilterActive () {
      return this.contactTypeFilterActiveCount === 1
    },
    isInvitationFilterActive () {
      return Boolean(this.filters.invited)
    },
    isSourceFilterActive () {
      return Boolean(this.filters.source)
    },
    isDownloadLandingpageFilterActive () {
      return Boolean(this.filters.downloadLandingpage)
    },
    originList () {
      const list = originList
      const translatedList = list.map(({ value, text }) => ({
        value,
        text: this.$t(text)
      }))
      if (this.hasCrm) {
        translatedList.push({
          value: 'leadOriginCrm',
          text: this.$t('lead-list.lead-origin.import-crm', { crmName: this.crmName })
        })
      }
      return translatedList
    },
    crmContactStatusLabel () {
      if (this.platform === 'remax') return 'Kontaktart'
      if (this.hasCrm) {
        const label = {
          onOffice: 'Kontaktart',
          Propstack: 'Kontaktstatus',
          Flowfact: this.$t('labels.tags')
        }
        return `${this.crmName} ${label[this.crmName]}` || 'Kontaktstatus'
      }
      return 'Kontaktstatus'
    },

    assignedUserList () {
      const assignedUserList = (this.users ?? [])
        .map(user => ({
          value: user.id,
          text: `${user.firstname} ${user.lastname}`
        }))
        .sort(sortByString('text'))

      return [{
        value: 'unassigned',
        text: 'Nicht zugewiesen'
      }, ...assignedUserList]
    },

    invitedLandingpageList () {
      const landingpageName = (slug) => {
        const landingpage = this.company?.landingpages?.find(({ slug: landingpageSlug }) => landingpageSlug === slug)
        return landingpage?.name ?? slug
      }

      const invitedLandingapgeList = this.invitedLandingpageSlugs?.map(slug => ({
        value: slug,
        text: landingpageName(slug)
      })).sort(sortByString('text'))

      return [{
        value: 'ALL',
        text: 'Alle bereits Eingeladenen'
      }, {
        value: 'NONE',
        text: 'Alle bislang nicht Eingeladenen'
      },
      { header: 'Bereits eingeladen zu:' },
      ...invitedLandingapgeList]
    },

    downloadLandingpages () {
      return this.company?.landingpages?.filter(({ type }) => type === 'GUIDE' || type === 'CHECKLIST')
        .map(({ name, slug }) => ({
          value: slug,
          text: name
        })).sort(sortByString('text'))
    },

    trafficSourceList () {
      return this.totalTrafficBySourceForCompany
        ?.filter(({ source }) => Boolean(source))
        ?.map(({ source }) => ({
          value: source,
          text: source
        }))
        ?.sort(sortByString('text'))
    }
  },

  watch: {
    filters: {
      handler () {
        persistAllFilters({ hasCrm: this.hasCrm, filters: this.filters, availableTools: this.conversionFilters })
        this.activeFiltersCount = getActiveFilterCount({ hasCrm: this.hasCrm, availableTools: this.conversionFilters })
        this.$emit('filtersChanged', {
          filters: this.filters,
          activeFiltersCount: this.activeFiltersCount
        })
      },
      deep: true
    },
    filterContactType (newValue) {
      if (newValue === 'leadOriginAll') {
        this.filters.leadOriginConversion = true
        this.filters.leadOriginImport = true
        if (this.hasCrm) {
          this.filters.leadOriginCrm = true
        }
      } else if (newValue === 'leadOriginImport') {
        this.filters.leadOriginConversion = false
        this.filters.leadOriginImport = true
        if (this.hasCrm) {
          this.filters.leadOriginCrm = false
        }
      } else if (newValue === 'leadOriginCrm') {
        this.filters.leadOriginConversion = false
        this.filters.leadOriginImport = false
        this.filters.leadOriginCrm = true
      } else {
        this.filters.leadOriginConversion = true
        this.filters.leadOriginImport = false
        if (this.hasCrm) {
          this.filters.leadOriginCrm = false
        }
      }
    }
  },

  created () {
    if (this.hasCrm) {
      this.filters.leadOriginCrm = true
    }
    this.filters = restoreAllFilters({ hasCrm: this.hasCrm, availableTools: this.conversionFilters })
    this.activeFiltersCount = getActiveFilterCount({ hasCrm: this.hasCrm, availableTools: this.conversionFilters })
    this.filterContactType = this.mapFilterContactType()
  },

  mounted () {
    this.setInitialPanel()
    this.setFiltersFromQueryParams()
  },

  methods: {
    setInitialPanel () {
      if (Object.keys(this.$route.query).includes('filter')) {
        this.panel = 1
      }
    },
    mapFilterContactType () {
      if (this.filters.leadOriginImport && this.filters.leadOriginConversion) return 'leadOriginAll'
      if (this.filters.leadOriginImport) return 'leadOriginImport'
      if (this.filters.leadOriginCrm) return 'leadOriginCrm'
      return 'leadOriginConversion'
    },
    close () {
      this.$emit('close')
      if (Object.keys(this.$route.query).includes('filter')) {
        this.$router.push('/leads')
      }
    },
    async updateContactTypes (selectedTags) {
      this.$set(this.filters, 'tags', selectedTags)
    },
    setFiltersFromQueryParams () {
      const { filter } = this.$route.query || {}
      const allowedParams = [
        'valuations',
        'propertyRequests',
        'returnCalls',
        'new'
      ]
      if (filter && allowedParams.includes(filter)) {
        if (filter === 'new') {
          this.filters.currentStatus = 'new'
        } else {
          this.filters[filter] = true
        }
      }
    },
    resetFilters () {
      this.filters.currentStatus = null
      this.filters.assignedUser = null
      this.filters.invited = null
      this.filters.source = null
      this.filters.downloadLandingpage = null
      this.filters.leadOriginConversion = true
      this.filters.leadOriginImport = true
      if (this.hasCrm) {
        this.filters.leadOriginCrm = true
        this.$set(this.filters, 'tags', [])
      }
      this.filters.postalShipping = false
      this.conversionFilters.forEach(({ key }) => { this.filters[key] = false })
      this.filterContactType = this.mapFilterContactType()
      const { filter } = this.$route.query || {}
      if (filter) this.$router.push({ path: '/leads', replace: true })
    }
  },
  apollo: {
    company: {
      query: COMPANY,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      }
    },
    invitedLandingpageSlugs: {
      query: LANDINGPAGE_SLUGS_USED_FOR_INVITATION
    },
    users: {
      query: USERS,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      }
    },
    totalTrafficBySourceForCompany: {
      query: TOTAL_TRAFFIC_BY_SOURCE_FOR_COMPANY
    }
  }
}
</script>

<style scoped>
.dialog-content-overflow{
  max-height: 100%;
  overflow: scroll
}
.gap{
  gap: 10px;
}
.filter-width{
  width: 240px
}

</style>
<style>
.filter-panel > .v-expansion-panel-content__wrap{
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 0px;
  padding-bottom: 10px;
}
</style>
