<template>
  <v-select
    v-model="selectedTags"
    :items="tags"
    :label="$t('labels.tags')"
    hide-details
    multiple
    outlined
    chips
    @change="handleChange"
  />
</template>

<script>
import LEAD_TAGS from '../queries/LeadTags.gql'

export default {
  props: {
    initialSelectedTags: {
      type: Array,
      default: () => []
    },
    crmName: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      tags: [],
      selectedTags: this.initialSelectedTags
    }
  },
  watch: {
    initialSelectedTags: {
      handler (newValue) {
        this.selectedTags = newValue
      },
      deep: true
    }
  },
  methods: {
    prepareTagName (name) {
      try {
        const preparedName = name.split(':')[2]
        if (preparedName !== 'null') {
          return preparedName
        } else {
          return this.$t('labels.crm.no-status', { crm: this.crmName })
        }
      } catch (e) {
        return name
      }
    },
    handleChange () {
      this.$emit('change', this.selectedTags)
    }
  },
  apollo: {
    tags: {
      query: LEAD_TAGS,
      update (data) {
        return data.leadTags.filter(name => name.startsWith('crm:')).map(name => ({ text: this.prepareTagName(name), value: name }))
      }
    }
  }
}
</script>
